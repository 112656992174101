
import { defineComponent, computed, ref, onMounted } from "vue";
import TransactionCartDetail from "@/components/transaction/transaction-detail/cart/TransactionCartDetail.vue";
import TransactionHeader from "@/components/transaction/transaction-detail/header/TransactionHeader.vue";
import PaymentSelectedChanelPanel from "./component/PaymentSelectedChanelPanel.vue";
import TransactionService from "@/service/transaction-service/TransactionService";
import { StatusChangeResponse } from "@/types/master/StatusChange.interface";
import { DefaultResponse } from "@/types/master/DefaultResponse.interface";
import { useRoute } from "vue-router";
import { Bankcode } from "@/types/master/Bankcode.interface";
import {
  PaymentForm,
  PaymentFormResponse,
} from "@/types/transaction/TransactionPayment.interface";
import PaymentTransferPanel from "./component/PaymentTransferPanel.vue";
import { useStore } from "@/store";
import { TransactionActionTypes } from "@/store/modules/transaction/action-types";
import { GlobalActionTypes } from "@/store/modules/global/action-types";

export default defineComponent({
  components: {
    TransactionCartDetail,
    TransactionHeader,
    PaymentSelectedChanelPanel,
    PaymentTransferPanel,
  },
  emits: ["handleChangePaymentProove"],
  setup(props, { emit }) {
    const store = useStore();
    const transaction = computed(() => store.getters.getTransaction);
    const transactionService = new TransactionService();
    const route = useRoute();

    const isSelectedPaymentMethod = ref<boolean>(false);
    const updatePaymentMethodMessage = ref<string>("");
    const changeMethodeFlag = ref<boolean>(false);
    const selectedBanking = ref<Bankcode>();
    const paymentForm = ref<PaymentForm>();

    const onHandleChangeBuyerInfo = () => {
      transactionService
        .onChangeStatus(transaction.value.id, 9)
        .then((data: StatusChangeResponse) => {
          if (data.result == 1) {
            store.dispatch(
              TransactionActionTypes.SET_TRANSACTION,
              transaction.value.id.toString()
            );
          }
        });
    };

    const onUpdatePaymentMethode = () => {
      if (transaction.value.paymentMethodId) {
        store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, true);
        transactionService
          .onUpdatePayment(
            transaction.value.id.toString(),
            transaction.value.paymentMethodId
          )
          .then((res: DefaultResponse) => {
            if (res.result == 1) {
              store.dispatch(
                TransactionActionTypes.SET_TRANSACTION,
                transaction.value.id.toString()
              );
              //isSelectedPaymentMethod.value = true;
              changeMethodeFlag.value = false;
              onCheckPaymentMethode();
            }
          });
      } else {
        updatePaymentMethodMessage.value = "กรุณาเลือกช่องทางการชำระเงิน";
      }
    };

    const onCheckPaymentMethode = () => {
      if (transaction.value.paymentMethodId !== 1) {
        setTimeout(() => {
          if (transaction.value.paymentMethodId == 4) {
            if (store.getters.getPlatform == 2) {
              window.open(
                route.params.transactionId + "/payment/" + selectedBanking.value
              );
            } else {
              getPaymentForm(transaction.value.paymentMethodId, 2);
            }
          } else {
            getPaymentForm(
              transaction.value.paymentMethodId,
              store.getters.getPlatform
            );
          }
        }, 200);
      } else {
        isSelectedPaymentMethod.value = true;
      }
    };

    const getPaymentForm = (paymentMethodId: number, isFacebook: number) => {
      transactionService
        .onGetPaymentForm(
          transaction.value.id.toString(),
          paymentMethodId,
          selectedBanking.value,
          isFacebook
        )
        .then((data: PaymentFormResponse) => {
          if (data.result == 1) {
            window.location.href = data.detail.paymentUrl;
          }
        });
    };

    const onHandleIsSelectedPaymentMethod = () => {
      if (!changeMethodeFlag.value) {
        isSelectedPaymentMethod.value = true;
      } else {
        isSelectedPaymentMethod.value = false;
      }
    };

    const handleChangePaymentProove = () => {
      console.log("handleChangePaymentProove", 3232323);
      emit("handleChangePaymentProove", false);
    };

    const onSetIsSelectedPaymentMethod = () => {
      isSelectedPaymentMethod.value = false;
    };

    onMounted(() => {
      if (transaction.value.paymentMethodId == 0) {
        isSelectedPaymentMethod.value = false;
      } else if (transaction.value.statusId == 2) {
        isSelectedPaymentMethod.value = false;
      } else if (transaction.value.statusId == 3) {
        isSelectedPaymentMethod.value = true;
      } else {
        isSelectedPaymentMethod.value = true;
      }
    });

    return {
      transaction,
      isSelectedPaymentMethod,
      updatePaymentMethodMessage,
      paymentForm,
      onHandleChangeBuyerInfo,
      onUpdatePaymentMethode,
      onHandleIsSelectedPaymentMethod,
      handleChangePaymentProove,
      onSetIsSelectedPaymentMethod,
    };
  },
});
