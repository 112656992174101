import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Panel, {
      header: "ช่องทางการชำระเงิน",
      toggleable: true,
      collapsed: _ctx.isCollapsed,
      "onUpdate:collapsed": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isCollapsed) = $event))
    }, {
      icons: _withCtx(() => [
        _createElementVNode("span", {
          class: "text-gray-1 f-size-14",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isCollapsed = !_ctx.isCollapsed))
        }, "ดูรายละเอียด")
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentMethods, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index + 'paymentMethodRadio',
            onClick: ($event: any) => (_ctx.onHandleSelectePayment(item)),
            class: "p-field-radiobutton p-m-3 p-py-3 border p-pl-3"
          }, [
            _createVNode(_component_RadioButton, {
              id: item.name,
              name: "paymentMethod",
              value: item.id,
              modelValue: _ctx.transaction.paymentMethodId,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.transaction.paymentMethodId) = $event))
            }, null, 8, ["id", "value", "modelValue"]),
            _createElementVNode("label", {
              for: item.name
            }, _toDisplayString(item.name), 9, _hoisted_3)
          ], 8, _hoisted_2))
        }), 128))
      ]),
      _: 1
    }, 8, ["collapsed"])
  ]))
}