
import AddressUserForm from "./AddressUserDetail.vue";
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  nextTick,
  watch,
} from "vue";
import ShippingAddressDetailForm from "./ShippingAddressDetailForm.vue";
import { GlobalActionTypes } from "@/store/modules/global/action-types";
import BillingAddressDetailForm from "@/components/transaction/status9/component-guest/BillingAddressDetailForm.vue";
import { useStore } from "@/store";
import { ValidationArgs } from "@vuelidate/core";
import AddressListPanel from "../component/AddressListPanel.vue";

export default defineComponent({
  components: {
    AddressUserForm,
    ShippingAddressDetailForm,
    BillingAddressDetailForm,
    AddressListPanel,
  },
  props: {
    v: {
      required: true,
      type: Object as PropType<ValidationArgs>,
    },
    sessionId: {
      required: true,
      type: String,
    },
    isSelfPickup: {
      required: true,
      type: Boolean,
    },
  },
  emits: ["onSetSelfPick", "onCalculateShippingCost", "setSelfPickupAddress"],
  setup(props, { emit }) {
    const stroe = useStore();
    const transaction = computed(() => stroe.getters.getTransaction);
    const isSelfPick = ref<boolean>(false);
    const selfPickupAddress = ref<string>("");

    const store = useStore();

    const setSelfPickupAddressDetail = () => {
      emit("setSelfPickupAddress", selfPickupAddress.value);
    };

    const onGetSelPickFlag = () => {
      isSelfPick.value = props.isSelfPickup;
    };

    const onCalculateShippingCost = () => {
      nextTick(() => {
        store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, true);
      });
      emit("onCalculateShippingCost");
    };

    const setSelfPickupAddress = (value: string) => {
      emit("setSelfPickupAddress", value);
    };

    onMounted(() => {
      onGetSelPickFlag();
    });

    return {
      transaction,
      isSelfPick,
      selfPickupAddress,
      onCalculateShippingCost,
      setSelfPickupAddress,
      setSelfPickupAddressDetail,
    };
  },
});
