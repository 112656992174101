
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      required: false,
      type: String,
    },
    text: {
      required: false,
      type: String,
    },

    name: {
      required: false,
      type: String,
    },
    options: {
      required: true,
      type: Array,
    },
    modelValue: {
      required: false,
      type: [String, Number],
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    v: {
      required: false,
      type: Object,
    },
    isDisplay: {
      required: false,
      type: Boolean,
    },
    placeholder: {
      required: false,
      type: String,
    },
    field: {
      required: false,
      type: String,
    },
    isAddress: {
      required: false,
      type: Boolean,
    },
    oninput: {
      required: false,
      type: String,
    },
  },
  emits: ["onSearchList", "onSelectItem", "update:modelValue", "onClearItem"],
  setup(props, { emit }) {
    const onSearchList = (event: any) => {
      emit("onSearchList", event);
    };
    const onSelectItem = (event: any) => {
      emit("onSelectItem", event);
    };
    const onClearItem = (event: any) => {
      emit("onClearItem", event);
    };
    return { onSearchList, onSelectItem, onClearItem };
  },
});
