
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    textFloat: {
      required: true,
      type: String,
    },
    placeholder: {
      required: true,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
    },
    detail: {
      required: false,
      type: String,
    },
    name: {
      required: false,
      type: String,
    },
    modelValue: {
      required: false,
      type: [String, Number],
    },
    rows: {
      required: false,
      type: [String, Number],
    },
    isDisplay: {
      required: false,
      type: Boolean,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    v: {
      required: false,
      type: Object,
    },
    img: {
      required: false,
      type: String,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    // const input = ref(props.value);
    return {};
  },
});
