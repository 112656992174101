import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TransactionStatus1Panel = _resolveComponent("TransactionStatus1Panel")!
  const _component_TransactonPolicyPanel = _resolveComponent("TransactonPolicyPanel")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.transactionStep == 1)
      ? (_openBlock(), _createBlock(_component_TransactionStatus1Panel, {
          key: 0,
          onSetTransactionStep: _ctx.setTransactionStep
        }, null, 8, ["onSetTransactionStep"]))
      : _createCommentVNode("", true),
    (_ctx.transactionStep == 2)
      ? (_openBlock(), _createBlock(_component_TransactonPolicyPanel, {
          key: 1,
          onOnSubmit: _ctx.onSubmit
        }, null, 8, ["onOnSubmit"]))
      : _createCommentVNode("", true)
  ]))
}