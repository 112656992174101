
import { useStore } from "@/store";
import { PaymentForm } from "@/types/transaction/TransactionPayment.interface";
import { defineComponent, computed, PropType } from "vue";
import PaymentChanelForm from "./PaymentChanelForm.vue";

export default defineComponent({
  components: {
    PaymentChanelForm,
  },
  props: {
    updatePaymentMethodMessage: {
      required: true,
      type: String,
    },
    paymentForm: {
      required: false,
      type: Object as PropType<PaymentForm>,
    },
  },
  emits: ["onHandleChangeBuyerInfo", "onUpdatePaymentMethode"],
  setup(props, { emit }) {
    const store = useStore();
    const transaction = computed(() => store.getters.getTransaction);

    const onSubmit = () => {
      emit("onUpdatePaymentMethode");
    };

    const onHandleChangeBuyerInfo = () => {
      emit("onHandleChangeBuyerInfo");
    };
    const onHandleSelectePayment = () => {
      console.log("onHandleSelectePayment");
    };

    const onSelectedBankCode = () => {
      console.log("onSelectedBankCode");
    };

    return {
      transaction,
      onSubmit,
      onHandleChangeBuyerInfo,
      onHandleSelectePayment,
      onSelectedBankCode,
    };
  },
});
