
import TransactionService from "@/service/transaction-service/TransactionService";
import { defineComponent, computed, ref, onMounted } from "vue";
import TransactionCompleteHeader from "@/components/transaction/transaction-detail/header/TransactionCompleteHeader.vue";
import TransactionCartDetail from "@/components/transaction/transaction-detail/cart/TransactionCartDetail.vue";
import TransactionAddressDetails from "@/components/transaction/transaction-detail/address/TransactionAddressDetail.vue";
import { SlipDetail } from "@/types/transaction/TransactionPayment.interface";
import { useStore } from "@/store";

export default defineComponent({
  components: {
    TransactionCompleteHeader,
    TransactionCartDetail,
    TransactionAddressDetails,
  },
  setup() {
    const store = useStore();
    const transaction = computed(() => store.getters.getTransaction);
    const transactionService = new TransactionService();

    const transferSlip = ref<SlipDetail>({
      bankAccountId: 0,
      amount: 0,
      imageList: [],
      transferDate: new Date().toString(),
      transferTime: new Date().toString(),
    });

    const getPaymentSlip = async () => {
      await transactionService
        .getOrderUploadSlip(transaction.value.id)
        .then((data: SlipDetail) => {
          transferSlip.value = data;
        });
    };

    onMounted(async () => {
      await getPaymentSlip();
    });

    return { transaction, transferSlip };
  },
});
