
import { defineComponent, computed, ref } from "vue";
import TransactionHeader from "@/components/transaction/transaction-detail/header/TransactionHeader.vue";
import TransactionCartDetail from "@/components/transaction/transaction-detail/cart/TransactionCartDetail.vue";
import { useStore } from "@/store";

export default defineComponent({
  components: {
    TransactionHeader,
    TransactionCartDetail,
  },
  emits: ["setTransactionStep"],
  setup(props, { emit }) {
    const store = useStore();
    const transaction = computed(() => store.getters.getTransaction);

    const onSubmit = () => {
      emit("setTransactionStep", 2);
    };

    const setTransactionStep = (value: number) => {
      emit("setTransactionStep", value);
    };

    return { transaction, setTransactionStep, onSubmit };
  },
});
