
import { computed, defineComponent, PropType, ref } from "vue";
import InputTextCustom from "@/components/inputs/InputText.vue";
import InputAutoComplete from "@/components/inputs/InputAutoComplete.vue";
import {
  Address,
  AddressInformation,
} from "@/types/transaction/TransactionAddress.interface";
import { useStore } from "@/store";
import ModalAlertError from "@/components/modal/ModalAlertError.vue";
import useVuelidate, { ValidationArgs } from "@vuelidate/core";
// import ModalAddAddress from "./ModalAddAddress.vue";
import { required, numeric, minLength, maxLength } from "@vuelidate/validators";
import { DefaultResponse } from "@/types/master/DefaultResponse.interface";
import { GlobalActionTypes } from "@/store/modules/global/action-types";
import TransactionService from "@/service/transaction-service/TransactionService";

const alpha = (value: string) => {
  if (value.match(/^[ก-๙a-zA-Z]*$/)) {
    return true;
  } else {
    return false;
  }
};
const address = (value: string) => {
  if (value.match(/^[ก-๙A-Za-z0-9'\\.\-\s\\,\\/]*$/)) {
    return true;
  } else {
    return false;
  }
};
export default defineComponent({
  components: {
    InputTextCustom,
    ModalAlertError,
    // ModalAddAddress,
    InputAutoComplete,
  },

  props: {
    address: {
      required: true,
      type: Object as PropType<Address>,
    },
    sessionId: {
      required: true,
      type: String,
    },
    transaction: {
      required: true,
      type: Object,
    },
    addressList: {
      required: true,
      type: Array,
    },
  },
  emits: ["handleSelectAddress", "getAddressList" , "onDeleteAddress"],
  setup(props, { emit }) {
    const displayModal = ref(false);
    const displayAddAdress = ref<boolean>(false);
    const store = useStore();
    const form = ref<Address>();
    const modalAlertError = ref<InstanceType<typeof ModalAlertError>>();
    const modalMessage = ref<string>("");
    const filteredProvince = ref<AddressInformation[]>([]);
    // const modalAddAddress = ref<InstanceType<typeof ModalAddAddress>>();
    const addressSelected = ref<Address>();
    const transaction = computed(() => store.getters.getTransaction);
    const v$ = useVuelidate();
    const transactionService = new TransactionService();

    const onClearItem = (event: any) => {
      if (form.value) {
        form.value.district = "";
        form.value.province = "";
        form.value.zipcode = "";
        form.value.zipcodeExternalId = 0;
      }
    };
    const onSelectItem = (event: any) => {
      if (form.value) {
        form.value.district = event.value?.districtInfo.districtNameLocal;
        form.value.province = event.value?.provinceInfo.provinceNameLocal;
        form.value.zipcode = event.value?.zipcode;
        form.value.zipcodeExternalId = event.value?.id;
      }
    };

    const searchProvince = (event: any) => {
      if (form.value) {
        form.value.zipcode = form.value?.zipcode.replace(/[^0-9]+/g, "");
        if (form.value.zipcode.length > 1) {
          transactionService
            .getAddressDropdownList(form.value?.zipcode)
            .then((res: AddressInformation[]) => {
              filteredProvince.value = res;
            })
            .catch((error) => {
              store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, false);
              if (error.message) {
                modalMessage.value = error.message;
              } else {
                modalMessage.value = error;
              }
              modalAlertError.value?.setShowModalError(true);
            });
        }
      }
    };

    const handlerZipcode = (event: any) => {
      if (!form.value) return;
      form.value.zipcode = event.replace(/[^0-9]+/g, "");
    };

    const openModal = () => {
      const address = computed(() => JSON.parse(JSON.stringify(props.address)));
      form.value = address.value;
      displayModal.value = true;
    };
    const closeModal = () => {
      displayModal.value = false;
    };

    const onHideModalError = () => {
      console.log("onHideModalError");
    };

    const handleSelectAddress = (address: Address) => {
      transaction.value.buyerInfo.billingAddressId = address.id;
      transaction.value.buyerInfo.billingAddress = JSON.parse(
        JSON.stringify(address)
      );
      emit("handleSelectAddress", transaction.value.buyerInfo.billingAddress);
    };

    const editAddress = (address?: Address) => {
      console.log(address);
      if (address) {
        form.value = address;
      } else {
        addressSelected.value = {
          address: "",
          district: "",
          firstname: "",
          lastname: "",
          province: "",
          road: "",
          subdistrict: "",
          zipcode: "",
          id: 0,
        } as Address;
      }
      displayAddAdress.value = true;
    };

    const openCreateAddress = () => {
      v$.value.form.$reset();
      form.value = {
        address: "",
        district: "",
        firstname: "",
        lastname: "",
        province: "",
        road: "",
        subdistrict: "",
        zipcode: "",
        id: 0,
      } as Address;
      displayAddAdress.value = true;
    }

    const openModalAddAddress = () => {
      v$.value.form.$reset();
      displayAddAdress.value = true;
    };

    const closeModalAddAddress = () => {
      displayAddAdress.value = false;
    };

    const beforeSaveAddress = async () => {
      v$.value.form.$touch();
      if (v$.value.form.$error) {
        return;
      }
      if (form.value?.id == 0) {
        await onSaveAddress();
      } else {
        await onUpdaleAddress();
      }
    };

    const onSaveAddress = async () => {
      store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, true);
      transactionService
        .saveAddress(form.value, props.sessionId)
        .then((res: DefaultResponse) => {
          // closeModal();
          displayAddAdress.value = false;
          emit("getAddressList");
        })
        .catch((error) => {
          store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, false);
          if (error.message) {
            modalMessage.value = error.message;
          } else {
            modalMessage.value = error;
          }
          modalAlertError.value?.setShowModalError(true);
        });
    };

    const onUpdaleAddress = async () => {
      store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, true);
      transactionService
        .updateAddress(form.value, props.sessionId)
        .then((res: DefaultResponse) => {
          closeModal();
          displayAddAdress.value = false;
          emit("getAddressList");
        })
        .catch((error) => {
          store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, false);
          if (error.message) {
            modalMessage.value = error.message;
          } else {
            modalMessage.value = error;
          }
          modalAlertError.value?.setShowModalError(true);
        });
    };

    const onDeleteAddress = (address: Address) => {
      emit("onDeleteAddress", address);
    };

    return {
      displayModal,
      form,
      v$,
      filteredProvince,
      modalAlertError,
      modalMessage,
      brand: computed(() => store.getters.getBrand),
      openModal,
      closeModal,
      onHideModalError,
      onSelectItem,
      onClearItem,
      handleSelectAddress,
      openModalAddAddress,
      displayAddAdress,
      editAddress,
      beforeSaveAddress,
      onSaveAddress,
      onUpdaleAddress,
      closeModalAddAddress,
      searchProvince,
      handlerZipcode,
      onDeleteAddress,
      openCreateAddress
    };
  },
  validations() {
    const form = this.form;
    return {
      form: {
        firstname: {},
        lastname: {},
        address: { required, address },
        district: {
          required,
          alpha,
        },
        subdistrict: {
          required,
          alpha,
        },
        province: {
          required,
          alpha,
        },
        zipcode: {
          required,
          numeric,
          minLength: minLength(5),
          maxLength: maxLength(5),
        },
      },
    };
  },
});
