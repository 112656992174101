
import TransactionService from "@/service/transaction-service/TransactionService";
import { Policy } from "@/types/master/Policy.interface";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  emits: ["onSubmit"],
  setup(props, { emit }) {
    const transactionService = ref(new TransactionService());
    const policy = ref<string>("");
    const agreePolicy = ref<boolean>(false);
    const agreeMargeting = ref<boolean>(false);

    const getPolicy = () => {
      transactionService.value.getPolicy().then((data: Policy) => {
        policy.value = data.description;
      });
    };

    const onSubmit = () => {
      emit("onSubmit");
    };

    onMounted(() => {
      getPolicy();
    });
    return { policy, agreePolicy, agreeMargeting, onSubmit };
  },
});
