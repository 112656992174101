
import TransactionService from "@/service/transaction-service/TransactionService";
import { defineComponent, computed, ref, onMounted } from "vue";
import TransactionCompleteHeader from "@/components/transaction/transaction-detail/header/TransactionCompleteHeader.vue";
import TransactionCartDetail from "@/components/transaction/transaction-detail/cart/TransactionCartDetail.vue";
import TransactionAddressDetails from "@/components/transaction/transaction-detail/address/TransactionAddressDetail.vue";
import { DeliveryInformation } from "@/types/master/DeliveryInformation.interface";
import { ContactInformation } from "@/types/master/ContactInformation.interface";
import { useStore } from "@/store";

export default defineComponent({
  components: {
    TransactionCompleteHeader,
    TransactionCartDetail,
    TransactionAddressDetails,
  },
  setup() {
    const store = useStore();
    const transaction = computed(() => store.getters.getTransaction);
    const transactionService = new TransactionService();

    const deliveryText = ref<string>("");
    const contactInfo = ref<ContactInformation[]>([]);

    const onCheckTrackingStatus = () => {
      window.location.href = transaction.value.trackingNo.urlLink;
    };

    const getDeliveryText = () => {
      transactionService.getDeliveryText().then((data: DeliveryInformation) => {
        deliveryText.value = data.description;
      });
    };

    const getContact = () => {
      transactionService
        .getContact(transaction.value.brandId)
        .then((data: ContactInformation[]) => {
          contactInfo.value = data;
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    onMounted(() => {
      getDeliveryText();
      getContact();
    });

    return { transaction, deliveryText, contactInfo, onCheckTrackingStatus };
  },
});
