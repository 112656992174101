import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0dbb4497"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "div-input" }
const _hoisted_2 = {
  key: 0,
  class: "display-only"
}
const _hoisted_3 = {
  key: 0,
  class: "text-error"
}
const _hoisted_4 = ["placeholder", "name", "rows", "required", "value"]
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 2,
  class: "text-desc pl-2"
}
const _hoisted_7 = { key: 3 }
const _hoisted_8 = {
  key: 0,
  class: "text-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isDisplay)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(['input-custom', { error: _ctx.isValidate }])
    }, [
      _createElementVNode("label", null, [
        _createTextVNode(_toDisplayString(_ctx.textFloat) + " ", 1),
        (_ctx.isRequired)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("textarea", {
        class: _normalizeClass(['custom-input f-regular']),
        placeholder: _ctx.placeholder,
        name: _ctx.name,
        rows: _ctx.rows,
        required: _ctx.required,
        value: _ctx.modelValue,
        onInput: _cache[0] || (_cache[0] = (event) => _ctx.$emit('update:modelValue', event.target.value))
      }, null, 40, _hoisted_4)
    ], 2),
    (_ctx.img)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: _ctx.img,
          alt: "logo-lang",
          class: "logo-lang"
        }, null, 8, _hoisted_5))
      : _createCommentVNode("", true),
    (_ctx.detail)
      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.detail), 1))
      : _createCommentVNode("", true),
    (_ctx.v && _ctx.v.$error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (_ctx.v.required == false)
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, "กรุณากรอกข้อมูลให้ครบถ้วน"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}