
import TransactionService from "@/service/transaction-service/TransactionService";
import { useStore } from "@/store";
import { GlobalActionTypes } from "@/store/modules/global/action-types";
import { PaymentMethode } from "@/types/transaction/TransactionPayment.interface";
import { defineComponent, computed, ref, onMounted } from "vue";

export default defineComponent({
  setup() {
    const store = useStore();
    const transaction = computed(() => store.getters.getTransaction);
    const transactionService = new TransactionService();
    const paymentMethods = ref<PaymentMethode[]>([]);
    const isCollapsed = ref<boolean>(false);

    const getPaymentList = () => {
      transactionService
        .getPaymentList(store.getters.getBrand.brandId)
        .then((data: PaymentMethode[]) => {
          paymentMethods.value = data;
          store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, false);
          if (transaction.value.paymentMethodId == 0) {
            onHandleSelectePayment(data[0]);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    onMounted(() => {
      getPaymentList();
    });

    const onHandleSelectePayment = (item: PaymentMethode) => {
      transaction.value.paymentMethodId = item.id;
    };
    return { transaction, paymentMethods, isCollapsed, onHandleSelectePayment };
  },
});
