
import TransactionService from "@/service/transaction-service/TransactionService";
import { useStore } from "@/store";
import { GlobalActionTypes } from "@/store/modules/global/action-types";
import { TransactionActionTypes } from "@/store/modules/transaction/action-types";
import { DefaultResponse } from "@/types/master/DefaultResponse.interface";
import { SlipDetail } from "@/types/transaction/TransactionPayment.interface";
import { defineComponent, computed, ref, onMounted } from "vue";
import PaymentBankAccount from "./PaymentBankAccount.vue";
import PaymentSlipForm from "./PaymentSlipForm.vue";

import { ListImageModel } from "@/types/transaction/Transaction.listimage";

export default defineComponent({
  components: {
    PaymentBankAccount,
    PaymentSlipForm,
  },
  emits: [
    "onHandleIsSelectedPaymentMethod",
    "handleChangePaymentProove",
    "onSetIsSelectedPaymentMethod",
  ],
  setup(props, { emit }) {
    const store = useStore();
    const transaction = computed(() => store.getters.getTransaction);
    const transactionService = new TransactionService();

    const transferSlip = ref<SlipDetail>();
    const imageList = ref<string[]>([]);
    const imageDisplayList = ref<string[]>([]);
    const fileNameLists = ref<string[]>([]);
    const uploadImageList = ref<string[]>([]);
    const imageIndex = ref<number>(-1);
    const imageIdLists = ref<number[]>([]);
    const uploadSlipMessage = ref<string>("");

    const listImage = ref<ListImageModel[]>([]);
    const delListImage = ref<ListImageModel[]>([]);

    const onDeleteImageItem = (item: any) => {
      // แยกรูปที่มีอยู่เพื่อส่งไปลบใน database
      let delArr = listImage.value.filter((x) => {
        if (x.name == item.name && x.status == "old") {
          return x;
        }
      });

      delListImage.value = [...delListImage.value, ...delArr];

      // ลบรูปออกตรง render image
      listImage.value = listImage.value.filter((x) => {
        return x.name != item.name;
      });

      // if (transferSlip.value?.imageList[index]) {
      //   transferSlip.value?.imageList.filter((el) => {
      //     if (el.imageUrl == transferSlip.value?.imageList[index].imageUrl) {
      //       imageIdLists.value?.push(el.id);
      //       transferSlip.value?.imageList.splice(index, 1);
      //     }
      //   });
      // }
      // imageList.value.splice(index, 1);
      // fileNameLists.value.splice(imageIndex.value, 1);
      // if (imageDisplayList.value.length > 0) {
      //   imageDisplayList.value.splice(index, 1);
      // }

      // uploadImageList.value.splice(index, 1);
    };

    const setImageIndex = (index: number) => {
      imageIndex.value = index;
    };

    const onFileChange = (
      e: UIEvent & { target: HTMLInputElement & { files: Array<string> } }
    ) => {
      if (e.target.files.length > 0) {
        const files = e.target.files;
        Array.from(files).forEach((element: any) => {
          hadleFileChangeImage(element);
        });
      }
      e.target.value = "";
    };

    const hadleFileChangeImage = (value: any) => {
      var reader = new FileReader();
      reader.readAsDataURL(value);

      reader.onload = async () => {
        let im = new Image();
        if (reader.result) {
          im.src = reader.result.toString();
          im.onload = async () => {
            let obj = {
              name: value.name,
              id: listImage.value.length,
              imageUrl: im.src,
              amount: 0,
              transferDate: "",
              status: "new",
            };

            listImage.value.push(obj);

            // if (imageIndex.value < 0) {
            //   imageList.value.push(im.src);
            //   fileNameLists.value.push(value.name);
            //   uploadImageList.value.push(im.src);
            // } else {
            //   if (transferSlip.value?.imageList[imageIndex.value]) {
            //     transferSlip.value.imageList.filter((el) => {
            //       if (
            //         el.imageUrl ==
            //         transferSlip.value?.imageList[imageIndex.value].imageUrl
            //       ) {
            //         imageIdLists.value?.push(el.id);
            //         uploadImageList.value.unshift(im.src);
            //       }
            //     });
            //   } else {
            //     uploadImageList.value.splice(imageIndex.value, 1, im.src);
            //   }
            //   imageList.value.splice(imageIndex.value, 1, im.src);
            //   fileNameLists.value.splice(imageIndex.value, 1, value.name);
            //   if (imageDisplayList.value.length > 0) {
            //     imageDisplayList.value.splice(imageIndex.value, 1, im.src);
            //   }
            // }
          };
        }
      };
    };

    const getPaymentSlip = () => {
      transactionService
        .getOrderUploadSlip(transaction.value.id)
        .then((data: SlipDetail) => {
          transferSlip.value = data;
          imageList.value = [];
          fileNameLists.value = [];
          uploadImageList.value = [];

          listImage.value = data.imageList.map((x) => {
            return {
              name: x.imageUrl.substring(x.imageUrl.lastIndexOf("/") + 1),
              ...x,
              status: "old",
            };
          });

          // transferSlip.value.imageList.forEach((element) => {
          //   imageDisplayList.value.push(element.imageUrl);
          //   let fileName = element.imageUrl.substring(
          //     element.imageUrl.lastIndexOf("/") + 1
          //   );
          //   fileNameLists.value.push(fileName);
          // });
          store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, false);
          if (transaction.value.paymentMethodId == 1) {
            if (transferSlip.value.imageList.length > 0) {
              onHandleIsSelectedPaymentMethod();
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const onHandleIsSelectedPaymentMethod = () => {
      emit("onHandleIsSelectedPaymentMethod");
    };

    const onSubmit = () => {
      if (validateUploadSlip()) {
        store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, true);
        upLoadPaymentSlip();
      } else {
        uploadSlipMessage.value = "กรุณาอัพโหลดอย่างน้อย 1 รูป";
      }
    };

    const validateUploadSlip = () => {
      if (listImage.value.length === 0) {
        return false;
      }
      return true;
    };

    const upLoadPaymentSlip = () => {
      let findNewImage = listImage.value.filter((x) => {
        if (x.status == "new") {
          return x;
        }
      });

      let finalNewUploadImage = findNewImage.map((x) => x.imageUrl);
      let findDeleteImage = delListImage.value.map((x) => x.id);

      transactionService
        .onUpdateloadSlip(
          transaction.value.id.toString(),
          finalNewUploadImage,
          findDeleteImage
        )
        .then((data: DefaultResponse) => {
          if (data.result == 1) {
            store.dispatch(
              TransactionActionTypes.SET_TRANSACTION,
              transaction.value.id.toString()
            );
            if (
              transaction.value.statusId == 3 ||
              transaction.value.statusId == 8
            ) {
              emit("handleChangePaymentProove");
            } else {
              setTimeout(() => {
                store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, false);
              }, 500);
            }
          }
        });
    };

    onMounted(() => {
      getPaymentSlip();
    });

    return {
      transferSlip,
      imageList,
      transaction,
      fileNameLists,
      uploadSlipMessage,
      onDeleteImageItem,
      imageDisplayList,
      setImageIndex,
      onFileChange,
      onSubmit,
      onHandleIsSelectedPaymentMethod,
      listImage,
    };
  },
});
