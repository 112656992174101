
import TransactionService from "@/service/transaction-service/TransactionService";
import { useStore } from "@/store";
import { GlobalActionTypes } from "@/store/modules/global/action-types";
import { TransactionActionTypes } from "@/store/modules/transaction/action-types";
import { StatusChangeResponse } from "@/types/master/StatusChange.interface";
import { defineComponent, computed, ref } from "vue";
import TransactionStatus1Panel from "./component/TransactionStatus1Panel.vue";
import TransactonPolicyPanel from "./component/TransactonPolicyPanel.vue";

export default defineComponent({
  components: {
    TransactionStatus1Panel,
    TransactonPolicyPanel,
  },
  setup() {
    const store = useStore();
    const transaction = computed(() => store.getters.getTransaction);
    const transactionService = new TransactionService();
    const transactionStep = ref<number>(1);

    const setTransactionStep = (value: number) => {
      transactionStep.value = value;
    };

    const onSubmit = () => {
      store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, true);
      transactionService
        .onChangeStatus(transaction.value.id, 9)
        .then(async (data: StatusChangeResponse) => {
          if (data.result == 1) {
           await store.dispatch(
              TransactionActionTypes.SET_TRANSACTION,
              transaction.value.id.toString()
            );
            store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, false);
          }
        });
    };

    return { transaction, transactionStep, setTransactionStep, onSubmit };
  },
});
