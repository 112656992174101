import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TransactionCompleteHeader = _resolveComponent("TransactionCompleteHeader")!
  const _component_TransactionPaymentDetail = _resolveComponent("TransactionPaymentDetail")!
  const _component_TransactionCartDetail = _resolveComponent("TransactionCartDetail")!
  const _component_TransactionStatus2 = _resolveComponent("TransactionStatus2")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.isEditPaymentProve)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", null, [
            _createVNode(_component_TransactionCompleteHeader, {
              transactionInvoice: _ctx.transaction.invoiceNo,
              statusId: _ctx.transaction.statusId,
              statusName: _ctx.transaction.statusName
            }, null, 8, ["transactionInvoice", "statusId", "statusName"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_TransactionPaymentDetail, { onHandleChangePaymentProove: _ctx.handleChangePaymentProove }, null, 8, ["onHandleChangePaymentProove"])
          ]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_TransactionCartDetail, {
              transaction: _ctx.transaction,
              isShowCart: true
            }, null, 8, ["transaction"])
          ])
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.isEditPaymentProve)
      ? (_openBlock(), _createBlock(_component_TransactionStatus2, {
          key: 1,
          onHandleChangePaymentProove: _ctx.handleChangePaymentProove
        }, null, 8, ["onHandleChangePaymentProove"]))
      : _createCommentVNode("", true)
  ]))
}