
import { NameTitle } from "@/types/master/NameTitle.interface";
import { computed, defineComponent, ref } from "vue";
import InputTextCustom from "@/components/inputs/InputText.vue";
import { useStore } from "@/store";
import InputAutoComplete from "@/components/inputs/InputAutoComplete.vue";
import {
  Address,
  AddressInformation,
} from "@/types/transaction/TransactionAddress.interface";
import TransactionService from "@/service/transaction-service/TransactionService";
import InputTextArea from "@/components/inputs/InputTextArea.vue";

export default defineComponent({
  components: {
    InputTextCustom,
    InputAutoComplete,
    InputTextArea,
  },
  props: {
    v: {
      required: true,
      type: Object,
    },
    transaction: {
      required: true,
      type: Object,
    },
  },
  emit: ["handleAddress"],
  setup(props, { emit }) {
    const nameTitleArray = ref<NameTitle[]>([]);
    const store = useStore();
    const form = ref<Address>();
    const filteredProvince = ref<AddressInformation[]>([]);
    const transactionService = new TransactionService();

    const onClearItem = (event: any) => {
      if (form.value) {
        form.value.district = "";
        form.value.province = "";
        form.value.zipcode = "";
        form.value.zipcodeExternalId = 0;
      }
    };

    const onSelectItem = (event: any) => {
      if (form.value) {
        form.value.district = event.value?.districtInfo.districtNameLocal;
        form.value.province = event.value?.provinceInfo.provinceNameLocal;
        form.value.zipcode = event.value?.zipcode;
        form.value.zipcodeExternalId = event.value?.id;
      }
    };

    const searchProvince = (event: any) => {
      if (form.value) {
        form.value.zipcode = form.value?.zipcode.replace(/[^0-9]+/g, "");
        if (form.value.zipcode.length > 1) {
          transactionService
            .getAddressDropdownList(form.value?.zipcode)
            .then((res: AddressInformation[]) => {
              filteredProvince.value = res;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    };

    const isNumber = (evt: any) => {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    };

    // form.value = computed(
    //   () => store.getters.getTransaction.buyerInfo.shippingAddress
    // ).value;

    form.value = computed(
      () => props.transaction.buyerInfo.shippingAddress
    ).value;

    const handleAddress = (evt: any) => {
      emit("handleAddress", evt);
      console.log("handleAddress", evt);
    };

    const handlerZipcode = (event: any) => {
      if (!form.value) return;
      form.value.zipcode = event.replace(/[^0-9]+/g, "");
    };

    return {
      isNumber,
      nameTitleArray,
      form,
      handleAddress,
      filteredProvince,
      searchProvince,
      onSelectItem,
      onClearItem,
      handlerZipcode,
    };
  },
  watch: {
    "transaction.buyerInfo.shippingAddress": function (newVal, oldVal) {
      // watch it
      this.form = newVal;
    },
  },
});
