
import { defineComponent, computed} from "vue";
import TransactionCompleteHeader from "@/components/transaction/transaction-detail/header/TransactionCompleteHeader.vue";
import TransactionCartDetail from "@/components/transaction/transaction-detail/cart/TransactionCartDetail.vue";
import TransactionAddressDetails from "@/components/transaction/transaction-detail/address/TransactionAddressDetail.vue";
import { useStore } from "@/store";
export default defineComponent({
  components: {
    TransactionCompleteHeader,
    TransactionCartDetail,
    TransactionAddressDetails,
  },
  setup() {
    const store = useStore();
    const transaction = computed(() => store.getters.getTransaction);
    return { transaction };
  },
});
