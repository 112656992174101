
import { computed, defineComponent, onMounted, ref } from "vue";
import TransacrionStatus1 from "./status1/TransacrionStatus1.vue";
import TransactionStatus9 from "./status9/TransactionStatus9.vue";
import TransactionStatus2 from "./status2/TransactionStatus2.vue";
import TransactionStatus3 from "./status3/TransactionStatus3.vue";
import TransactionStatus4 from "./status4/TransactionStatus4.vue";
import TransactionStatus5 from "./status5/TransactionStatus5.vue";
import TransactionOtherStatus from "./other/TransactionOtherStatus.vue";
import { useStore } from "@/store";

export default defineComponent({
  components: {
    TransacrionStatus1,
    TransactionStatus9,
    TransactionStatus2,
    TransactionStatus3,
    TransactionStatus4,
    TransactionStatus5,
    TransactionOtherStatus,
  },
  setup() {
    const store = useStore();
    const transaction = computed(() => store.getters.getTransaction);
    const displayPosition = ref(false);

    onMounted(() => {
      if (transaction.value.statusId == 8) {
        displayPosition.value = true;
      }
    });

    return {
      displayPosition,
      transaction,
    };
  },
});
