
import { defineComponent, onMounted } from "vue";
import TransactionDetailPanel from "@/components/transaction/TransactionDetailPanel.vue";

export default defineComponent({
  components: {
    TransactionDetailPanel,
  },
  setup() {
    return {};
  },
});
