
import TransactionService from "@/service/transaction-service/TransactionService";
import { defineComponent, computed, ref } from "vue";
import TransactionCompleteHeader from "@/components/transaction/transaction-detail/header/TransactionCompleteHeader.vue";
import TransactionCartDetail from "@/components/transaction/transaction-detail/cart/TransactionCartDetail.vue";
import TransactionPaymentDetail from "./component/TransactionPaymentDetail.vue";
import TransactionStatus2 from "@/components/transaction/status2/TransactionStatus2.vue";
import { useStore } from "@/store";
import { GlobalActionTypes } from "@/store/modules/global/action-types";
import { TransactionActionTypes } from "@/store/modules/transaction/action-types";

export default defineComponent({
  components: {
    TransactionCompleteHeader,
    TransactionCartDetail,
    TransactionPaymentDetail,
    TransactionStatus2,
  },
  setup() {
    const store = useStore();
    const transaction = computed(() => store.getters.getTransaction);

    const isEditPaymentProve = ref<boolean>(false);

    const handleChangePaymentProove = (value: boolean) => {
      console.log("handleChangePaymentProove", value);
      isEditPaymentProve.value = value;
      if (value == false) {
        store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, false);
        store.dispatch(
          TransactionActionTypes.SET_TRANSACTION,
          transaction.value.id.toString()
        );
      }
    };

    return { transaction, handleChangePaymentProove, isEditPaymentProve };
  },
});
