import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TransactionHeader = _resolveComponent("TransactionHeader")!
  const _component_TransactionCartDetail = _resolveComponent("TransactionCartDetail")!
  const _component_PaymentSelectedChanelPanel = _resolveComponent("PaymentSelectedChanelPanel")!
  const _component_PaymentTransferPanel = _resolveComponent("PaymentTransferPanel")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.transaction)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_TransactionHeader, {
            transactionInvoice: _ctx.transaction.invoiceNo,
            statusId: _ctx.transaction.statusId.toString(),
            statusName: _ctx.transaction.statusName,
            tittle: 'หมายเลขคำสั่งซื้อ',
            isDisplayInvoice: true
          }, null, 8, ["transactionInvoice", "statusId", "statusName"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createVNode(_component_TransactionCartDetail, {
        transaction: _ctx.transaction,
        isShowCart: true
      }, null, 8, ["transaction"])
    ]),
    (!_ctx.isSelectedPaymentMethod)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_PaymentSelectedChanelPanel, {
            paymentForm: _ctx.paymentForm,
            updatePaymentMethodMessage: _ctx.updatePaymentMethodMessage,
            onOnHandleChangeBuyerInfo: _ctx.onHandleChangeBuyerInfo,
            onOnUpdatePaymentMethode: _ctx.onUpdatePaymentMethode
          }, null, 8, ["paymentForm", "updatePaymentMethodMessage", "onOnHandleChangeBuyerInfo", "onOnUpdatePaymentMethode"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isSelectedPaymentMethod && _ctx.transaction.paymentMethodId == 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_PaymentTransferPanel, {
            onOnHandleIsSelectedPaymentMethod: _ctx.onHandleIsSelectedPaymentMethod,
            onHandleChangePaymentProove: _ctx.handleChangePaymentProove,
            onOnSetIsSelectedPaymentMethod: _ctx.onSetIsSelectedPaymentMethod
          }, null, 8, ["onOnHandleIsSelectedPaymentMethod", "onHandleChangePaymentProove", "onOnSetIsSelectedPaymentMethod"])
        ]))
      : _createCommentVNode("", true)
  ]))
}