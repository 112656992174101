
import TransactionService from "@/service/transaction-service/TransactionService";
import { StatusChangeResponse } from "@/types/master/StatusChange.interface";
import { SlipDetail } from "@/types/transaction/TransactionPayment.interface";
import { defineComponent, ref, computed, onMounted } from "vue";
import { useStore } from "@/store";
import { TransactionActionTypes } from "@/store/modules/transaction/action-types";

export default defineComponent({
  emits: ["handleChangePaymentProove"],
  setup(props, { emit }) {
    const transferSlip = ref<SlipDetail>({
      bankAccountId: 0,
      amount: 0,
      imageList: [],
      transferDate: new Date().toString(),
      transferTime: new Date().toString(),
    });

    const store = useStore();
    const transaction = computed(() => store.getters.getTransaction);
    const transactionService = new TransactionService();

    const getPaymentSlip = async () => {
      await transactionService
        .getOrderUploadSlip(transaction.value.id)
        .then((data: SlipDetail) => {
          transferSlip.value = data;
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const onBackToSelectPaymentMethode = () => {
      transactionService
        .onChangeStatus(transaction.value.id, 2)
        .then((data: StatusChangeResponse) => {
          store.dispatch(
            TransactionActionTypes.SET_TRANSACTION,
            transaction.value.id.toString()
          );
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const handleChangePaymentProove = () => {
      emit("handleChangePaymentProove", true);
    };

    onMounted(async () => {
      await getPaymentSlip();
    });

    return {
      transaction,
      transferSlip,
      onBackToSelectPaymentMethode,
      handleChangePaymentProove,
    };
  },
});
